import { Button, Typography } from '@pwskills/rachnaui';
import Link from 'next/link';
import React from 'react';

const MasterClass = () => {
  return (
    <div className="large:pl-20 max-w-screen-xlarge xlarge:!mx-auto flex">
      <div className="w-full medium:w-1/2 px-4 py-8">
        <Typography variant="bold" component="h2" className="pb-6">
          PW Skills Brings You MasterClass
        </Typography>
        <Typography variant="medium" component="body-regular" className="pb-6">
          Stuck in your job? Learn and grow with our masterclasses led by top industry experts. Dive
          into tech with seasoned leaders!
        </Typography>
        <Link href="/masterclasses?position=home_page&click_text=explore_masterclass&page_ref=home_page">
          <Button variant="primary" className="w-full medium:!w-fit">
            Explore Masterclass
          </Button>
        </Link>
      </div>
      <div className="hidden medium:!flex justify-end w-full medium:w-1/2">
        <img src="/images/masterClass/masterclass_landing.svg" alt="landing" />
      </div>
    </div>
  );
};

export default MasterClass;
